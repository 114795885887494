import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Metrics.css";
import { Store, GroupPresentation, UserMultiple } from "@carbon/react/icons"; 
import "@carbon/charts/styles.css";
import { FacilityDataTableComponent } from "../facility-data-table.component";
import ViewButton from "../view-button";
import { PoliceDataTableComponent } from '../police-data-table.component'; 
import {MyChartComponent} from '../mychart.component'

const Metrics = ({ district, dateArray }) => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const dateFrom = dateArray[0].toISOString().split('T')[0];
                const dateTo = dateArray[1].toISOString().split('T')[0];
                const response = await axios.get(`https://sgbv.caringsoulshomecare.com/api/?cmd=getDashboardData&district=${district}&date_from=${dateFrom}&date_to=${dateTo}`);
                setDashboardData(response.data.results);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (district) {
            fetchData();
        }
    }, [district, dateArray]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!dashboardData) {
        return <div>No data available</div>;
    }

    return (
        <>
          <div className="tile-container">
            <div className="tile tile-margin">
              <div className="tile-header">
                <div className="tile-items-container">
                  <div className="tile-icon"><Store size={50}/></div>
                  <div> Health Center Cases</div>
                </div>
                <div className="tile-bottom-style">
                  <div className="tile-item-value">{dashboardData.total_health_center_cases}</div>
                  <ViewButton/>
                </div>
              </div>
            </div>
            <div className="tile tile-margin">
              <div className="tile-header">
                <div className="tile-items-container">
                  <div className="tile-icon"><Store size={50}/></div>
                  <div> Police Station Cases</div>
                </div>
                <div className="tile-bottom-style">
                  <div className="tile-item-value">{dashboardData.total_police_station_cases}</div>
                  <ViewButton/>
                </div>
              </div>
            </div>
            <div className="tile tile-margin">
              <div className="tile-header">
                <div className="tile-items-container">
                  <div className="tile-icon"><GroupPresentation size={50}/></div>
                  <div>Cases Reported</div>
                </div>
                <div className="tile-bottom-style">
                  <div className="tile-item-value">{dashboardData.total_cases_reported}</div>
                  <ViewButton/>
                </div>
              </div>
            </div>
            <div className="tile">
              <div className="tile-header">
                <div className="tile-items-container">
                  <div className="tile-icon"><UserMultiple size={50}/></div>
                  <div> Cases Completed</div>
                </div>
                <div className="tile-bottom-style">
                  <div className="tile-item-value">{dashboardData.total_cases_received}</div>
                  <ViewButton/>
                </div>
              </div>
            </div>
          </div>   
          <div className="item-chart-container">
            <div className="item-chart item-chart-left">
            <FacilityDataTableComponent district={district} dateFrom={dateArray[0].toISOString().split('T')[0]} dateTo={dateArray[1].toISOString().split('T')[0]} />
            </div>
            <div className="item-chart item-chart-left">
              <PoliceDataTableComponent district={district} dateFrom={dateArray[0].toISOString().split('T')[0]} dateTo={dateArray[1].toISOString().split('T')[0]} />
            </div>
          </div>

          <div className="item-chart-container">
            
            <div className="item-chart"> 
              <MyChartComponent district={district} dateFrom={dateArray[0].toISOString().split('T')[0]} dateTo={dateArray[1].toISOString().split('T')[0]} />
            </div>
          </div>
        </>
    );
};

export default Metrics;
