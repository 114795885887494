import React, { useState, useEffect } from 'react';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination
} from '@carbon/react';
import '@carbon/styles/css/styles.css';
import './facilityDataTableComponent.css';

export const PoliceDataTableComponent = ({ district, dateFrom, dateTo }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://sgbv.caringsoulshomecare.com/api/?cmd=getAllServicesByDistrict&district=${district}&date_from=${dateFrom}&date_to=${dateTo}`);
        const data = await response.json();

        // Check if data.results is an array
        if (Array.isArray(data.results)) {
          const filteredResults = data.results.filter(item => item.duty_station_type === '1');
          const formattedRows = filteredResults.map((item, index) => ({
            id: (index + 1).toString(),
            duty_station: item.duty_station,
            Case_Investigation_count: item.Case_Investigation_count || 'N/A',
            Arrest_of_the_Perpetrator_count: item.Arrest_of_the_Perpetrator_count || 'N/A',
            Facilitating_Court_Case_count: item.Facilitating_Court_Case_count || 'N/A',
            Psychosocial_Support_count: item.Psychosocial_Support_count || 'N/A',
            Other_specify_count: item.Other_specify_count || 'N/A',
          }));
          setRows(formattedRows);
        } else {
          throw new Error('API response is not an array');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [district, dateFrom, dateTo]); // Update when district, dateFrom, or dateTo changes

  const headers = [
    {
      key: 'duty_station',
      header: 'Police Station',
    },
    {
      key: 'Case_Investigation_count',
      header: 'Case Investigation',
    },
    {
      key: 'Arrest_of_the_Perpetrator_count',
      header: 'Arrest of the Perpetrator',
    },
    {
      key: 'Facilitating_Court_Case_count',
      header: 'Facilitating Court Case',
    },
    {
      key: 'Psychosocial_Support_count',
      header: 'Psychosocial Support',
    },
    {
      key: 'Other_specify_count',
      header: 'Other specify',
    },
  ];

  const getCurrentPageRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="data-table-container">
      <DataTable rows={getCurrentPageRows()} headers={headers}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
      <Pagination
        totalItems={rows.length}
        pageSize={rowsPerPage}
        pageSizes={[5, 10, 15, 20]}
        onChange={({ page, pageSize }) => {
          setCurrentPage(page);
          setRowsPerPage(pageSize);
        }}
      />
    </div>
  );
};
