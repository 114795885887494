import React, { useEffect, useState } from 'react';
import { StackedBarChart } from '@carbon/charts-react';
import PropTypes from 'prop-types';

export const MyChartComponent = ({ district, dateFrom, dateTo }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://sgbv.caringsoulshomecare.com/api/?cmd=getDashboardData&district=${district}&date_from=${dateFrom}&date_to=${dateTo}`); 
                const result = await response.json();

                const transformedData = [
                    { "group": "ageGroup", "key": "50+", "value": parseInt(result.results.age_50_plus) || 0 },
                    { "group": "ageGroup", "key": "45 - 49", "value": parseInt(result.results.age_45_49) || 0 },
                    { "group": "ageGroup", "key": "40 - 44", "value": parseInt(result.results.age_40_44) || 0 },
                    { "group": "ageGroup", "key": "35 - 39", "value": parseInt(result.results.age_35_39) || 0 },
                    { "group": "ageGroup", "key": "30 - 34", "value": parseInt(result.results.age_30_34) || 0 },
                    { "group": "ageGroup", "key": "25 - 29", "value": parseInt(result.results.age_25_29) || 0 },
                    { "group": "ageGroup", "key": "20 - 24", "value": parseInt(result.results.age_20_24) || 0 },
                    { "group": "ageGroup", "key": "15 - 19", "value": parseInt(result.results.age_15_19) || 0 },
                    { "group": "ageGroup", "key": "10 - 14", "value": parseInt(result.results.age_10_14) || 0 },
                    { "group": "ageGroup", "key": "Below 9", "value": parseInt(result.results.below_9) || 0 }
                ];

                setData(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [district, dateFrom, dateTo]);

    const options = {
        title: "Case by Age Group",
        axes: {
            left: {
                scaleType: "labels"
            },
            bottom: {
                stacked: false
            }
        },
        height: "600px"
    };

    return (
        <div className="item-chart">
            <StackedBarChart data={data} options={options} />
        </div>
    );
};

MyChartComponent.propTypes = {
    district: PropTypes.string.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired
};

export default MyChartComponent;
